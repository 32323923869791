function setupDropdowns(selector) {
  const dropdowns = document.querySelectorAll(selector);

  dropdowns.forEach((dropdown) => {
    const button = dropdown.querySelector('[data-dropdown-button="true"]');
    const content = dropdown.querySelector('[data-dropdown-content="true"]');

    if (!button || !content) {
      console.warn('Dropdown button or content not found:', dropdown);
      return;
    }

    button.setAttribute('aria-expanded', 'false');

    const toggleDropdown = (event) => {
      const isExpanded = button.getAttribute('aria-expanded') === 'true';
      button.setAttribute('aria-expanded', String(!isExpanded));
      content.classList.toggle('hidden', isExpanded);
      content.classList.toggle('grid', !isExpanded);

      const svg = button.querySelector('svg');
      if (svg) {
        svg.style.transform = isExpanded ? 'rotate(0deg)' : 'rotate(180deg)';
      }
    };

    button.addEventListener('click', toggleDropdown);

    content.addEventListener('click', (event) => {
      if (event.target && event.target.classList.contains('dropdown-item')) {
        const selectedText = event.target.innerText.split('\n')[0]; // Получаем текст кнопки
        button.childNodes[0].textContent = selectedText; // Обновляем текст на кнопке
        toggleDropdown(); // Закрыть дропдаун после выбора

        event.stopPropagation();
      }
    });
  });
}

window.setupDropdowns = setupDropdowns;

document.addEventListener('DOMContentLoaded', () => {
  console.log('DOM полностью загружен');

  // Инициализация элементов
  const menuBtn = document.getElementById('menu-btn');
  const primaryMenu = document.getElementById('primary-menu');
  const videoFacade = document.querySelector('.video-facade');
  const playButton = document.querySelector('.play-button');
  const modals = document.querySelectorAll('.modal');
  const uploadButton = document.getElementById('upload-button');
  const fileInput = document.getElementById('file-input');
  const profileImage = document.getElementById('profile-image');
  const input = document.querySelectorAll('.input-search');
  const searchIcon = document.querySelectorAll('.search-icon');
  const closeIcon = document.querySelectorAll('.close-icon');
  const btnInputSearch = document.querySelectorAll('.input-search-btn');

  // Обработчик для кнопки меню
  if (menuBtn) {
    menuBtn.addEventListener('click', () => {
      primaryMenu?.classList.toggle('hidden');
      menuBtn.classList.toggle('menu__btn-close');
      const isExpanded = menuBtn.getAttribute('aria-expanded') === 'true';
      menuBtn.setAttribute('aria-expanded', !isExpanded);
    });
  }

  // Загрузка YouTube видео

  const loadYouTubeVideo = () => {
    if (!videoFacade) return;
    const videoId = videoFacade.dataset.videoId;
    const videoTitle = videoFacade.dataset.videoTitle;
    const iframe = document.createElement('iframe');
    iframe.src = `https://www.youtube.com/embed/${videoId}?autoplay=1&si=3-PBD2-vjudnr8qr`;
    iframe.title = videoTitle;
    iframe.className = 'aspect-video h-full w-full rounded-xl';
    iframe.allow =
      'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share';
    iframe.referrerPolicy = 'strict-origin-when-cross-origin';
    iframe.allowFullscreen = true;
    videoFacade.innerHTML = '';
    videoFacade.appendChild(iframe);
  };

  // Обработчики событий для видео
  if (videoFacade) {
    videoFacade.addEventListener('click', loadYouTubeVideo);
  }
  if (playButton) {
    playButton.addEventListener('click', (e) => {
      e.stopPropagation();
      loadYouTubeVideo();
    });
  }

  // Обработчики для открытия модальных окон с видео

  const teachersDiv = document.querySelector('#teachersDiv');
  if (teachersDiv) {
    teachersDiv.addEventListener('click', (event) => {
      if (event.target && event.target.classList.contains('open-modal-button')) {
        const modalButton = event.target;

        const modalId = modalButton.getAttribute('data-modal-target');
        const videoId = modalButton.getAttribute('data-video-id');
        const videoTitle = modalButton.getAttribute('data-video-title');

        const modal = document.getElementById(modalId);
        const modalContent = document.getElementById(`${modalId}-content`);

        modalContent.innerHTML = `
            <button
              type="button"
              class="close-modal absolute -right-2 -top-2 flex h-6 w-6 rounded-full bg-white text-custom-darker-gray transition-colors hover:bg-custom-purple hover:text-white"
              data-modal-hide="default-modal"
            >
              <svg class="absolute left-0.5 top-0.5 h-4 w-4" aria-hidden="true">
                <use xlink:href="#icon-close"></use>
              </svg>
              <span class="sr-only">Закрыть окно</span>
            </button>
            <div class="aspect-video">
              <iframe
                class="youtube-video h-full w-full rounded-xl"
                src="https://www.youtube.com/embed/${videoId}"
                title="${videoTitle}"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>
          `;

        const closeButton = modalContent.querySelector('.close-modal');
        closeButton.addEventListener('click', () => {
          modal.classList.add('hidden');
          modalContent.innerHTML = '';
        });

        modal.classList.remove('hidden');
        event.stopPropagation();
      }
    });
  } else {
    console.error('Элемент #teachersDiv не найден!');
  }

  // Настройка дропдаунов
  setupDropdowns('.dropdown');

  // Обработчики для языковых фильтров
  document.querySelectorAll('.language-filter-item').forEach((button) => {
    button.addEventListener('click', function () {
      const isPressed = this.getAttribute('aria-pressed') === 'true';
      const newState = !isPressed;
      this.setAttribute('aria-pressed', newState);
      this.classList.toggle('bg-custom-lavender', newState);
      const closeIcon = this.querySelector('svg');
      if (closeIcon) {
        closeIcon.classList.toggle('hidden', !newState);
      }
    });
  });

  // Обработчики для модальных окон
  const openModal = (modal) => {
    modal.classList.remove('hidden');
    modal.querySelector('.close-modal')?.focus();
  };

  const closeModal = (modal) => {
    modal.classList.add('hidden');
    const videoFrame = modal.querySelector('.youtube-video');
    if (videoFrame) {
      videoFrame.src = videoFrame.src; // Перезагрузка видео
    }
    document.querySelector(`[data-modal-target="${modal.id}"]`)?.focus();
  };

  document.querySelectorAll('.open-modal, .ord_lesson').forEach((button) => {
    button.addEventListener('click', () => {
      const modal = document.getElementById(button.dataset.modalTarget);
      openModal(modal);
    });
  });

  document.querySelectorAll('.close-modal').forEach((button) => {
    button.addEventListener('click', () => {
      const modal = button.closest('.modal');
      closeModal(modal);
    });
  });

  // Закрытие модальных окон по клавише Escape
  document.addEventListener('keydown', (event) => {
    if (event.key === 'Escape') {
      modals.forEach((modal) => {
        if (!modal.classList.contains('hidden')) {
          closeModal(modal);
        }
      });
    }
  });

  // Закрытие модальных окон по клику на свободной области
  modals.forEach((modal) => {
    modal.addEventListener('click', (event) => {
      if (event.target === modal) {
        // Проверяем, что клик произошел на модальном фоне
        closeModal(modal);
      }
    });
  });

  // Обработчики для поиска

  // Обработчики для каждого поля ввода
  input.forEach((inputField, index) => {
    const searchIconElement = searchIcon[index]; // Получаем соответствующую иконку поиска
    const closeIconElement = closeIcon[index]; // Получаем соответствующую иконку закрытия
    const btnInputSearchElement = btnInputSearch[index]; // Получаем соответствующую кнопку очистки

    if (inputField && searchIconElement && closeIconElement) {
      inputField.addEventListener('input', function () {
        if (this.value.length > 0) {
          searchIconElement.classList.add('hidden');
          closeIconElement.classList.remove('hidden');
        } else {
          searchIconElement.classList.remove('hidden');
          closeIconElement.classList.add('hidden');
        }
      });

      // При клике на кнопку
      btnInputSearchElement.addEventListener('click', function () {
        inputField.value = ''; // Очищаем поле
        searchIconElement.classList.remove('hidden');
        closeIconElement.classList.add('hidden');
      });
    }
  });

  // Обработчик для радио-кнопок
  const radioButtons = document.querySelectorAll('input[name="native-speaker"]');
  radioButtons.forEach((radio) => {
    radio.addEventListener('change', () => {
      radioButtons.forEach((btn) => {
        const label = btn.closest('label');
        if (label) {
          label.classList.toggle('active', btn.checked);
        }
      });
    });
  });

  // Обработчики для загрузки фото профиля
  if (uploadButton) {
    uploadButton.addEventListener('click', () => {
      fileInput.click(); // Вызываем клик на скрытом input
    });
  }

  if (fileInput) {
    fileInput.addEventListener('change', (event) => {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = function (e) {
          profileImage.src = e.target.result; // Устанавливаем новое изображение
        };
        reader.readAsDataURL(file); // Читаем файл как Data URL
      }
    });
  }
});

// Filter dropdown

document.addEventListener('DOMContentLoaded', function () {
  const filterButton = document.querySelector('[data-dropdown-filter="true"]');
  const filterContent = document.querySelector('[data-dropdown-content-filter="true"]');
  const chevronIcon = filterButton ? filterButton.querySelector('svg') : null; // Проверяем наличие filterButton

  if (!filterButton || !filterContent) {
    console.warn('Filter button or content not found.');
    return;
  }

  // Установка aria-expanded по умолчанию
  filterButton.setAttribute('aria-expanded', 'false');

  const toggleDropdownFilter = () => {
    const isExpanded = filterButton.getAttribute('aria-expanded') === 'true';
    filterButton.setAttribute('aria-expanded', String(!isExpanded));
    filterContent.classList.toggle('hidden', isExpanded);

    // Поворот иконки шеврона
    if (chevronIcon) {
      chevronIcon.style.transform = isExpanded ? 'rotate(0deg)' : 'rotate(180deg)';
    }
  };

  filterButton.addEventListener('click', toggleDropdownFilter);
});
